.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  z-index: 1080;
  background: #02084b;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 1s;
}

.sidebar {
  -ms-overflow-style: none;
}

.sidebar {
  overflow: -moz-scrollbars-none;
}
.sidebar::-webkit-scrollbar {
  width: 0 !important;
}

.ant-layout-sider-children {
  .menu {
    background-color: #02084b;
  }
}
.ant-menu-sub.ant-menu-inline {
  background-color: #02084b;
}
.menu {
  .ant-menu-item {
    color: #969ba0;
    display: flex;
    align-items: center;
    height: 50px !important;
    &:active {
      background-color: #02084b;
    }

    a {
      color: #969ba0;
      font-size: 16px;
    }

    a.active,
    a:hover {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: scaleY(0.0001);
      opacity: 0;
      border-left: solid 6px #cc9933;
      //border-radius: 0 10px 10px 0;
    }

    &::after {
      border: 0;
    }

    .anticon {
      width: 20px;
      font-size: 20px;
    }

    &.ant-menu-item-selected {
      background-color: #232b64;
      color: #fff;

      a {
        color: #fff;
      }

      &::before {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  .ant-menu-submenu {
    color: #fff;

    .ant-menu-submenu-title {
      color: #fff;
      &:active {
        background-color: #02084b;
      }
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #fff;
      }
    }
  }
  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    background-color: #02084b;
    color: #fff;

    .ant-menu-submenu-title {
      color: #fff;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #fff;
      }
    }

    .ant-menu-item-active,
    .ant-menu-submenu-title:hover {
      color: #fff;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #fff;
      }
    }
  }
}
.ivory-submenu > ul > li {
  padding-left: 24px !important;
}

.ivory-submenu {
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      font-weight: 300;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #02084b !important;
    border-color: #02084b !important;
    border-radius: 3px !important;
    /* padding: .55rem !important; */
  }
  &::after {
    border-color: #02084b !important;
  }
}
.redbtn {
  &.active {
    background: #f50100 !important;
  }
  &.focus {
    background: #f50100 !important;
  }
}

.card-container {
  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab-active {
            padding: 16px;
            background: #cc9933;
            border-radius: 10px 10px 0px 0px;
            .ant-tabs-tab-btn {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
