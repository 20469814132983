.ant-table-thead {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;

  & > tr > th {
    padding: 20px 16px 5px 16px !important;
    color: #9fa2b4 !important;
    font-weight: 500 !important;
    background-color: white !important;
    border-bottom: 1px solid !important;

    &::before {
      width: 0 !important;
    }
  }
}
.ant-table-tbody {
  & > tr > .ant-table-cell {
    color: #02084b;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border-bottom: 1.5px solid #2c348933;
  }
}

.ant-table-row:hover {
  cursor: pointer;
}
