@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: linear-gradient(
    177.48deg,
    #f2f3ff 18.87%,
    rgba(251, 248, 245, 0.432226) 41.74%,
    rgba(251, 248, 245, 0.432226) 46.53%,
    rgba(255, 250, 241, 0.19) 63.1%,
    #f2f3ff 77.25%
  );
  font-family: "Karla";
  font-style: normal;
  font-size: 16px;
}

a:hover {
  color: #cc9933 !important;
}

body::-webkit-scrollbar,
.DraftEditor-root::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 50px;
}

body::-webkit-scrollbar-track,
.DraftEditor-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
.DraftEditor-root::-webkit-scrollbar-thumb {
  background-color: #7b61ff;
  outline: 1px solid #cc9933;
}

.pdf-modal .ant-modal-wrap {
  z-index: 100000;
}

.rounded-modal > .ant-modal-content {
  @apply rounded-2xl overflow-hidden;
}

.no-padding-top .ant-modal-body {
  padding-top: 0 !important;
}

.ivry-bg {
  background-color: #f8f8ff;
}
.ant-layout {
  background-color: #f8f8ff;
}

.ivry-layout {
  background: #f8f8ff;
  margin-left: 220px;
  position: relative;
}
@media (max-width: 996px) {
  .ivry-layout {
    margin-left: 0;
  }
  .app-content {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sidebar {
    flex: 0 1;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0;
    &.open {
      flex: 0 0 220px !important;
      max-width: 220px !important;
      min-width: 220px !important;
      width: 220px !important;
    }
  }
}
@media (min-width: 1024px) {
  .sidebar {
    flex: 0 1;
    max-width: 220px !important;
    min-width: 220px !important;
    width: 0;
  }
}
.ivry-input {
  width: 100%;
  padding: 8px 11px;
  border-radius: 5px;
  &.ivry {
    padding-top: 11px;
    padding-bottom: 11px;
    color: #02084b;
    border-color: #6167a7;
    &:focus {
      box-shadow: none;
    }
    &.ant-input[disabled] {
      background: transparent;
      &:hover {
        border-color: #6167a7;
      }
    }
  }
  &.ivry-number {
    color: #02084b;
    border-color: #6167a7;
    &.ant-input-number-focused {
      box-shadow: none !important;
    }
    .ant-input-number-group {
      .ant-input-number-group-addon {
        border-color: #6167a7;
        background-color: transparent;
        //padding-block: 11px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
  .ant-input-prefix {
    margin-right: 15px;
  }
  &.pre {
    .ant-input-prefix {
      margin-right: 4px;
      opacity: 0.4;
    }
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input-lg {
    font-size: 14px;
  }
}
.ivry-add-button:hover {
  background: #2c3489;
  opacity: 0.8;
}
.ivry-transparent-button {
  border: 1px solid #2c3489;
  color: #2c3489;
  &:hover {
    background: #2c3489;
    opacity: 0.8;
    color: #fff;
    border: 1px solid #fff;
  }
}
.ivry-red-transparent-button {
  border: 1px solid #f50100;
  color: #f50100;
  &:hover {
    background: #f50100;
    opacity: 0.8;
    color: #fff;
    border: 1px solid #fff;
  }
  &:focus {
    border: 1px solid #f50100;
    color: #f50100 !important;
    background: transparent !important;
  }
}
.back-arrow {
  color: #2c3489;
  font-weight: 500;
  font-style: normal;

  &::before {
    content: " \003C";
    color: #555555;
    margin-right: 10px;
    width: 6px;
    height: 4px;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-modal-title {
  color: #2c3489;
}
.ivry-label {
  .ant-form-item-label {
    label {
      color: #2c3489;
      font-weight: 400;
    }
  }
}

.ant-form-item-label {
  .ant-form-item-required {
    &::before {
      position: absolute;
      right: -16px;
    }
  }
}

/*.ant-modal {
  &.transfer-modal {
    width: 400px !important;
  }
}*/
.ivry-cancel-button {
  border: 1px solid transparent;
  color: #948d8d;
  border: 0 !important;
  &:hover {
    opacity: 0.8;
    color: #948d8d !important;
    border: 1px solid #fff;
  }
}

.ivry-select {
  background-color: transparent;
  &.asPrefix {
    .ant-select-selector {
      height: 43px !important;
      padding-left: calc(3rem - 8px) !important;
      border-radius: 5px !important;
    }
  }

  &.ant-select-show-search {
    .ant-select-selector {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      .ant-select-selection-search {
        top: 5px;
      }
    }
  }
  .ant-select-selector {
    height: 43px !important;
    padding: 5px 11px !important;
    border-radius: 5px !important;
    border-color: #6167a7 !important;
    box-shadow: none !important;
    .ant-select-selection-item {
      color: #02084b;
    }
    &:focus {
      border-color: #6167a7;
    }
  }
}
.ant-select-single.ant-select-open {
  .ant-select-selection-item {
    color: #bfbfbf;
  }
}
.dashboard {
  .ant-select-selector {
    border: 0 !important;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
    border: 0 !important;
  }
}
.prefix-icon {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-radio {
  border-color: #2c3489;
  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: #2c3489;
      &::after {
        background-color: #02084b;
      }
    }
  }
  &.ant-radio-wrapper:hover {
    border-color: #2c3489;
  }
}

.canceltransparentbtn {
  background: transparent !important;
  border: 0 !important;
  color: #948d8d;
  &:hover {
    color: #2c3489 !important;
  }

  &:active {
    color: #948d8d !important;
  }
}

.transactionsShadow {
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12) !important;
}

.bottom__dots {
  height: 3px;
  width: 30px;
  background-color: #d9d9d9;
}

.slick-dots .slick-active .bottom__dots {
  width: 30px;
  overflow: hidden;
  background-color: #cc9933;
  top: 0;

  .active {
    background-color: #cc9933;
    top: 0;
    left: 0;
  }
}

.slick-dots {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  column-gap: 6px !important;
}

.slick-prev {
  left: -50px !important;
}

.slick-prev,
.slick-next {
  display: flex !important;
  align-items: center !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.no-padding-select-input .ant-select {
  padding: 0 !important;
}

.ant-input[disabled] {
  height: 100%;
  top: 0.5px;
  left: -0.5px;
}

.custom-upload-style-container .ant-upload-list {
  display: flex !important;
  justify-content: center !important;
}

.custom-upload-style-container .ant-upload-select {
  border: 0 !important;
  width: max-content !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #bbb;
}

.no-border {
  .ant-table-container {
    tr > th,
    tr > td {
      border-bottom-width: 0;
    }
  }
}

.no-border .ant-table-container tr:last-of-type td:nth-child(n + 2),
.no-border .ant-table-container tr:nth-last-child(3) td:nth-child(n + 2),
.no-border .ant-table-container tr:nth-last-child(5) td:nth-child(n + 2) {
  border-bottom-width: 2px;
}

.no-border .ant-table-container .ant-table-thead > tr > th {
  border-bottom-width: 0 !important;
}

.header-bg-FFFAF1 table thead tr th {
  background-color: #fffaf1 !important;
  border-bottom-width: 0 !important;
  padding-bottom: 20px !important;
}

.header-bg-F2F3FF table thead tr th {
  background-color: #f2f3ff !important;
  border-bottom-width: 0 !important;
  padding-bottom: 20px !important;
}

.label-full .ant-form-item-required {
  width: 100%;
}

.no-required-indicator .ant-form-item-required::before {
  display: none !important;
}

.grid-cols-18 {
  grid-template-columns: repeat(18, minmax(0, 1fr));
}

.col-span-18 {
  grid-column: span 18 / span 18;
}
.ant-popover-buttons {
  .ant-btn-primary {
    background: #1890ff;
  }
}
